import React from 'react';
import heroImg from '../assets/hero.png';
import { useEffect, useState } from 'react';
import CustomForm from './CustomForm';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/autoplay';

import img0 from '../assets/stemLogo.jpeg';
import img1 from '../assets/1.jpg';
import img2 from '../assets/2.jpg';
import img3 from '../assets/3.jpg';
import img4 from '../assets/4.jpg';
import img5 from '../assets/5.jpg';
import img6 from '../assets/6.png';
import img7 from '../assets/7.jpg';
import img8 from '../assets/8.jpg';
import img9 from '../assets/9.webp';
import img10 from '../assets/10.jpg';
import img11 from '../assets/11.jpg';
import img12 from '../assets/12.jpg';
import img13 from '../assets/13.jpg';
import img14 from '../assets/14.jpg';
import img15 from '../assets/15.jpg';
import img16 from '../assets/16.png';
import img17 from '../assets/17.jpeg';
import img18 from '../assets/18.jpg';
import img19 from '../assets/19.jpg';
import img20 from '../assets/20.jpg';
import img21 from '../assets/21.jpg';

const images = [
    img0, img17, img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img18, img19, img20, img21
  ];

const Hero = () => {
    const [showForm, setShowForm] = useState(false);

    const handleShowForm = () => {
        setShowForm(true);
    };

    const handleCloseForm = () => {
        setShowForm(false);
    };

    return (
        <>
            {showForm && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg relative transform transition-transform duration-300 ease-in-out scale-95 opacity-100 z-50 animate-popup">
                        <CustomForm formType="enquiry" title="Enquiry Form" handleClose={handleCloseForm} />
                    </div>
                </div>
            )}

            <div className={`flex flex-col items-center justify-between bg-white px-8 pt-8 pb-0 transition-all duration-300 ease-in-out ${showForm ? 'blur-sm' : ''}`}>
               
                <div className="py-4 w-full">
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 120,
                            modifier: 4,
                            slideShadows: true,
                        }}
                        autoplay={{
                            delay: 2000,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                        }}
                        modules={[EffectCoverflow, Autoplay]}
                        className="mySwiper"
                    >
                        {images.map((image, index) => (
                            <SwiperSlide key={index} className="flex justify-center">
                                <img
                                    src={image}
                                    alt={`Slide ${index + 1}`}
                                    className="w-[250px] h-[200px] md:w-[300px] md:h-[250px] lg:w-[600px] lg:h-[300px] object-cover rounded-lg"
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <div className="w-full flex items-center flex-col md:flex-row">

                    <div className="md:w-1/2 text-center md:text-left space-y-4">
                        <h2 className="text-lg md:text-3xl text-center font-semibold font-ubuntu text-black">
                            Empowering Future Innovators
                        </h2>

                        <div className="relative inline-block">
                            <h1 className="text-3xl md:text-5xl lg:text-6xl font-bold font-ubuntu text-center text-blue-400 relative z-10">
                                EMBRACE <span className="relative text-[#ff7722]">INNOVATION!</span>
                            </h1>
                        </div>

                        <div className='flex justify-center'>
                            <button className="border-2 border-black bg-black text-white px-4 py-2 sm:px-6 sm:py-3 md:px-8 md:py-3 rounded-full hover:bg-blue-400 hover:border-blue-400 transition duration-300 ease-in-out" onClick={handleShowForm}>Get Started</button>
                        </div>
                    </div>

                    <div className="md:w-1/2 flex flex-col justify-center items-center md:items-end">
                    <img src={heroImg} alt="Innovative Learning" className="w-full md:h-80 sm:h-40 object-contain" />
                    </div>

                </div>
            </div>

        </>

    )
}

export default Hero