import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import Swal from 'sweetalert2';

const CustomForm = ({ formType, title, jobCategories = 'No Category', handleClose }) => {

    const { control, handleSubmit, formState: { errors } } = useForm();

    // const onSubmit = async (data) => {
    //     const formData = new FormData();
    //     formData.append('entry.771997595', data.name);  // Name field
    //     formData.append('entry.886872971', data.email);  // Email field
    //     formData.append('entry.1400627763', data.phone);
    //     formData.append('entry.1303131353', data.description); // Description field
    //     // console.log(formData);

    //     for (let pair of formData.entries()) {
    //         console.log(pair[0]+ ', ' + pair[1]);
    //     }

    //     try {
    //         const response = await fetch('https://docs.google.com/forms/d/e/1FAIpQLSc7z0KNyASQ5FG_jKrpPoc-eSmBVTEHNSoGXiflGrCaAEUP2g/formResponse', { // Your Form ID
    //             method: 'POST',
    //             mode: 'no-cors',  // Important for avoiding CORS issues
    //             body: formData,
    //         });
    //         if (response.status === 200 || response.status === 0) {
    //             Swal.fire({
    //                 icon: 'success',
    //                 title: '<span style="color: green; font-weight: bold; font-family: ubuntu; font-size: 20px">Form submitted successfully!</span>',
    //                 showConfirmButton: false,
    //                 timer: 3000
    //             });
    //             handleClose(); // Close the form
    //         } else {
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'There was an issue submitting the form.',
    //                 text: 'Please try again later.'
    //             });
    //         }
    //     } catch (error) {
    //         console.error('Error submitting form:', error);
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Error submitting form.',
    //             text: 'Please try again later.'
    //         })
    //     }
    // }

    const onSubmit = () => {

    }
    

    const renderFields = () => {
        switch (formType) {
            case 'enquiry':
                return (
                    <>
                        <div className="flex flex-wrap -mx-2">
                            <InputField label="Name" name="name" control={control} errors={errors} required={true} className="w-full px-2" />
                        </div>
                        <div className="flex flex-wrap -mx-2">
                            <InputField label="Phone" name="phone" control={control} errors={errors} required={true} className="w-full md:w-1/2 px-2" />
                            <InputField label="Email" name="email" control={control} errors={errors} required={true} className="w-full md:w-1/2 px-2" />
                        </div>
                        <TextAreaField label="Description" name="description" control={control} />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="max-w-lg mx-auto p-4 border rounded shadow-md h-auto overflow-y-auto max-h-[80vh]">
            <h2 className="text-2xl font-bold text-center font-ubuntu text-[#ff7722] mb-4">{title}</h2>
            {renderFields()}
            <div className="flex justify-around items-center">
                <button type="submit" className="mt-4 bg-blue-500 text-white py-2 px-4 rounded">Submit</button>
                <button type="button" onClick={handleClose} className="mt-4 bg-red-500 text-white py-2 px-4 rounded">Close</button>
            </div>
        </form>
    );
};

const InputField = ({ label, name, control, value, errors = {}, className, required }) => (
    <div className={`mb-4 ${className}`}>
        <label className="block text-gray-900 font-semibold font-ubuntu">
            {label} {required && <span className="text-red-500">*</span>}
        </label>
        <Controller
            name={name}
            control={control}
            defaultValue={value || ''}
            rules={{ required: required ? `${label} is required` : false }}
            render={({ field }) => (
                <input
                    {...field}
                    value={field.value || ''}
                    className="w-full p-2 border rounded"
                    readOnly={!!value}
                />
            )}
        />
        {errors && errors[name] && (
            <p className="text-red-500 text-xs">{errors[name]?.message}</p>
        )}
    </div>
);




const TextAreaField = ({ label, name, control }) => (
    <div className="mb-4">
        <label className="block text-gray-900 font-semibold font-ubuntu">{label}</label>
        <Controller
            name={name}
            control={control}
            render={({ field }) => <textarea {...field} className="w-full p-2 border rounded"></textarea>}
        />
    </div>
);

export default CustomForm