import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import Header from './components/Header.js';
import { createBrowserRouter, Outlet, RouterProvider, useLocation } from 'react-router-dom';
import Body from './components/Body.js';
import About from './components/About.js';
import Program from './components/Program.js';
import Impact from './components/Impact.js';
import Careers from './components/Careers.js';
import Contact from './components/Contact.js';

const AppLayout = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div className='my-16'>
            <Header />
            <Outlet />
        </div>
    );
};

const appRouter = createBrowserRouter([
    {
        path: "/",
        element: <AppLayout />,
        children: [
            {
                path: "/",
                element: <Body />
            },
            {
                path: "/about",
                element: <About />
            },
            {
                path: "/program",
                element: <Program />
            },
            {
                path: "/impact",
                element: <Impact />
            },
            {
                path: "/careers",
                element: <Careers />
            },
            {
                path: "/contact",
                element: <Contact />
            }
        ]
    }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />)