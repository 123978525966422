import React from 'react';
import Hero from './Hero.js';
import ChooseUs from './ChooseUs.js';
import Approach from './Approach.js';
import StudentOfferings from './StudentOfferings.js';
import OurPrograms from './OurPrograms.js';


const Body = () => {
  return (
    <div>
      <Hero />
      <Approach />
      <StudentOfferings />
      <ChooseUs />
      <OurPrograms bgColor="bg-orange-100" hoverColor="bg-violet-100" />
    </div>
  )
}

export default Body