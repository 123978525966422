import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.jpeg';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {
        windowWidth >= 1024 ? (
          <nav className="bg-white border-gray-200 dark:bg-gray-900 fixed top-0 left-0 z-50 w-full">
            <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
              <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                <img src={logo} className="h-12" alt="Logo" />
              </a>
              <div className="hidden w-full md:block md:w-auto">
                <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                  <li className="mb-2 md:mb-0">
                    <NavLink to="/" style={({ isActive }) => ({ color: isActive ? "#ff7722" : "black" })} className="block font-bold py-2 px-3 dark:text-white md:dark:text-white">
                      Home
                    </NavLink>
                  </li>
                  <li className="mb-2 md:mb-0">
                    <NavLink to="/about" style={({ isActive }) => ({ color: isActive ? "#ff7722" : "black" })} className="block font-bold py-2 px-3 dark:text-white md:dark:text-white">
                      About
                    </NavLink>
                  </li>
                  <li className="mb-2 md:mb-0">
                    <NavLink to="/program" style={({ isActive }) => ({ color: isActive ? "#ff7722" : "black" })} className="block font-bold py-2 px-3 dark:text-white md:dark:text-white">
                      Products and Services
                    </NavLink>
                  </li>
                  <li className="mb-2 md:mb-0">
                    <NavLink to="/impact" style={({ isActive }) => ({ color: isActive ? "#ff7722" : "black" })} className="block font-bold py-2 px-3 dark:text-white md:dark:text-white">
                      News and Events
                    </NavLink>
                  </li>
                  <li className="mb-2 md:mb-0">
                    <NavLink to="/careers" style={({ isActive }) => ({ color: isActive ? "#ff7722" : "black" })} className="block font-bold py-2 px-3 dark:text-white md:dark:text-white">
                      Careers
                    </NavLink>
                  </li>
                  <li className="mb-2 md:mb-0">
                    <NavLink to="/contact" style={({ isActive }) => ({ color: isActive ? "#ff7722" : "black" })} className="block font-bold py-2 px-3 dark:text-white md:dark:text-white">
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        ) : (
          <nav className="bg-white border-gray-200 dark:bg-gray-900 fixed top-0 left-0 z-50 w-full">
            <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4">

              <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                <img src={logo} className="h-10" alt="Logo" />
              </a>

              <button onClick={() => setIsOpen(!isOpen)} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg 1200px:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                <span className="sr-only">Open main menu</span>
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                </svg>
              </button>

              <div className={`lg:flex w-full ${isOpen ? 'block' : 'hidden'} lg:block`}>
                <ul className={`font-medium flex flex-col lg:flex-row p-4 lg:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 lg:space-x-8 rtl:space-x-reverse lg:mt-0 lg:border-0 lg:bg-white dark:bg-gray-800 lg:dark:bg-gray-900 dark:border-gray-700`}>
                  <li className='mb-2 lg:mb-0'>
                    <NavLink to="/" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "#ff7722" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded lg:border-0 lg:p-0">
                      Home
                    </NavLink>
                  </li>
                  <li className="mb-2 lg:mb-0">
                    <NavLink to="/about" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "#ff7722" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded lg:border-0 lg:p-0">
                      About
                    </NavLink>
                  </li>
                  <li className="mb-2 lg:mb-0">
                    <NavLink to="/program" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "#ff7722" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded lg:border-0 lg:p-0">
                      Products and Services
                    </NavLink>
                  </li>
                  <li className="mb-2 lg:mb-0">
                    <NavLink to="/impact" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "#ff7722" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded lg:border-0 lg:p-0">
                      News and Events
                    </NavLink>
                  </li>
                  <li className="mb-2 lg:mb-0">
                    <NavLink to="/careers" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "#ff7722" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded lg:border-0 lg:p-0">
                      Careers
                    </NavLink>
                  </li>
                  <li className="mb-2 lg:mb-0">
                    <NavLink to="/contact" onClick={() => setIsOpen(false)} style={({ isActive }) => ({ color: isActive ? "white" : "black", backgroundColor: isActive ? "#ff7722" : "rgb(249 250 251)" })} className="block py-2 px-3 rounded lg:border-0 lg:p-0">
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </div>

            </div>
          </nav>
        )
      }
    </>
  )
}

export default Header;