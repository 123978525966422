import React from 'react';
import roboticsImg from '../assets/robotics.jpg';
import iotImg from '../assets/iot.jpg';
import droneImg from '../assets/P31.jpg';
import arvrImg from '../assets/6.png';
import printingImg from '../assets/P51.jpg';
import codingImg from '../assets/coding.jpg';

const OurPrograms = ({bgColor, hoverColor}) => {
    const data = [
        {
            title: 'Robotics & Automation',
            description: 'Understanding the fundamentals of robotics, mechatronics, and AI applications that drive automation in industries.',
            image: roboticsImg,
        },
        {
            title: 'IoT & Smart Technologies',
            description: 'Teaching students how smart devices interact in an interconnected world, and how to develop and program such systems.',
            image: iotImg,
        },
        {
            title: 'Drone Engineering',
            description: 'From drone mechanics to flight programming, students learn the inner workings of this high-demand technology.',
            image: droneImg,
        },
        {
            title: 'AR/VR Development',
            description: 'Creating immersive experiences and applications in the rapidly growing virtual and augmented reality markets.',
            image: arvrImg,
        },
        {
            title: '3D Design & Printing',
            description: 'Training students to conceptualize, design, and build physical models using advanced CAD and 3D printing technologies.',
            image: printingImg,
        },
        {
            title: 'Coding & Software Development',
            description: 'Building logical thinking and problem-solving skills through coding and software development, which forms the backbone of all technological innovations.',
            image: codingImg,
        },
    ];
    return (
        <div className={`py-8 ${bgColor}`}>
            <h2 className="text-4xl font-bold font-ubuntu mb-8 text-blue-500 text-center">
                Our <span className="text-[#ff7722]">Programs</span>
            </h2>
            <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 px-8 xl:px-0 mb-4 gap-8 xl:gap-16">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className={`bg-white text-center text-gray-800 rounded-lg p-6 shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl hover:${hoverColor} duration-300 ease-in-out`}
                    >
                        <div className="flex justify-center">
                            <img
                                src={item.image}
                                alt={item.title}
                                className="w-48 h-48 rounded-full object-cover mb-4"
                            />
                        </div>
                        <h3 className="text-xl font-semibold">{item.title}</h3>
                        <p className="mt-2">{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default OurPrograms