import React from 'react'

const Impact = () => {
  return (
    <div className="p-48 text-center flex justify-center text-5xl uppercase text-blue-500">
      <p>Coming Soon....</p>
    </div>
  )
}

export default Impact