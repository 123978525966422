import React from 'react';
import stemImg from '../assets/stem_based_approach.jpg';

const Approach = () => {
    return (
        <div className='bg-violet-100 py-8'>
            <div>
                <h2 className="text-4xl font-bold font-ubuntu mb-8 text-blue-500 text-center">
                    STEM-Based Approach for <span className="text-[#ff7722]">Tomorrow's Leaders</span>
                </h2>
            </div>

            <div className="flex flex-col md:flex-row items-center md:gap-4 justify-between p-8">
                <div className="md:w-1/2 w-full flex justify-center mb-8 md:mb-0">
                    <img src={stemImg} alt="About Us" className="w-96 lg:w-[480px] h-96 object-cover rounded-[100%]" />
                </div>
                <div className="md:w-1/2 w-full text-center md:text-left space-y-4">
                    <h2 className="text-sm md:text-base font-medium text-black">
                        Our <span className='font-semibold'>STEM (Science, Technology, Engineering and Mathematics)</span> based learning is carefully designed to be both theoretical and practical, encouraging students to learn concepts and immediately apply them in real-world scenarios.
                    </h2>
                    <h2 className="text-sm md:text-base font-semibold text-black">
                        The focus is on problem-solving, critical thinking, creativity, and collaborative work, providing students with the opportunity to understand the complexities of technology while nurturing their ability to innovate.
                    </h2>
                </div>
            </div>
        </div>
    )
}

export default Approach