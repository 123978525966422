import React, { useState, useEffect } from 'react';
import careerImg from '../assets/career.webp';
import CustomForm from './CustomForm';

const Careers = () => {
  const [showForm, setShowForm] = useState(false);

  const handleShowForm = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  const jobOpenings = [
    {
      title: "Product Developers / Trainers",
      company: "STEM Avishkar Pvt Ltd",
      location: "Mysore, India",
      experience: "0-2 years of experience",
      description: "STEM Avishkar Pvt Ltd is looking for passionate and dedicated trainers for emerging technologies like Robotics, IoT, AR/VR, Drones, 3D Printing, and AI. Trainers will guide students to learn these technologies through hands-on projects. Comprehensive training will be provided before deployment to client schools.",
      category: "Product Developers",
    },
    {
      title: "Marketing Coordinators",
      company: "STEM Avishkar Pvt Ltd",
      location: "Mysore, India",
      experience: "2-5 years of experience",
      description: "Drive our mission forward by engaging with the community and promoting our programs.",
      category: "Marketing",
    },
  ];

  return (
    <>
      {showForm && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg relative transform transition-transform duration-300 ease-in-out scale-95 opacity-100 z-50 animate-popup">
            <CustomForm formType="joinUs" title="Join Us" handleClose={handleCloseForm} />
          </div>
        </div>
      )}

      <div className="flex flex-col md:flex-row items-center md:gap-4 justify-between bg-white p-8">

        <div className="md:w-1/2 w-full text-center md:text-center space-y-4">

          <div className="relative inline-block">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold font-ubuntu text-center text-blue-400 relative z-10">
              Careers
            </h1>
          </div>

          <h2 className="text-sm md:text-base text-left font-medium text-black">
            We are always seeking talented individuals who are passionate about technology and education.
          </h2>

          <h2 className="text-sm md:text-base text-left font-medium text-black">
            Join our team of innovators and educators!
          </h2>
        </div>


        <div className="md:w-1/2 w-full flex justify-center mt-8 md:mt-0">
          <img src={careerImg} alt="Career" className="w-96 lg:w-[480px] h-80 object-cover rounded-full" />
        </div>


      </div>

      <div className="flex flex-col bg-violet-100 p-8">
        <div className="w-full text-center mb-8">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold font-ubuntu text-blue-400">
            Are You Ready To <span className="text-[#ff7722]">Join Our Team?</span>
          </h1>
        </div>

        <div className="flex flex-col md:grid md:grid-cols-2 mb-4 md:gap-8">
          {jobOpenings.map((job) => (
            <div key={job.title} className="bg-white p-4 md:p-8 rounded-lg shadow-md space-y-4 mb-8 md:mb-0">
              <h3 className="text-lg text-center md:text-xl font-semibold text-blue-400">{job.title}</h3>
              <p className="text-sm md:text-base text-black"><strong>Company:</strong> {job.company}</p>
              <p className="text-sm md:text-base text-black"><strong>Location:</strong> {job.location}</p>
              <p className="text-sm md:text-base text-black"><strong>Experience:</strong> {job.experience}</p>
              <p className="text-sm md:text-base text-black">{job.description}</p>
              <div className="flex justify-center items-center py-2">
                <button
                  className="border-2 border-black bg-black text-white px-4 py-2 sm:px-6 sm:py-3 md:px-8 md:py-3 rounded-full hover:bg-blue-400 hover:border-blue-400 transition duration-300 ease-in-out"
                  onClick={() => window.open('https://forms.gle/zezHVofZGpGjwFh96', '_blank')}
                >
                  Know More / Apply Now
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Careers