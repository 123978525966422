import React from 'react';
import beginnerImg from '../assets/beginner.webp';
import intermediateImg from '../assets/intermediate.webp';
import advancedImg from '../assets/advanced.webp';

const StudentOfferings = () => {
    const offerigsData = [
        {
            title: 'Beginner (Grade 1-4)',
            description: 'Introduction to coding, robotics basics, and problem-solving through interactive projects.',
            image: beginnerImg,
        },
        {
            title: 'Intermediate (Grade 5-7)',
            description: 'Exploring AI, Robotics, IoT, and 3D Printing through real-world applications.',
            image: intermediateImg,
        },
        {
            title: 'Advanced (Grade 8-9)',
            description: 'Dive deep into AI, Robotics, Drones, and AR/VR, with projects that challenge students to innovate and create.',
            image: advancedImg,
        },
    ];

    return (
        <div className="py-8 bg-orange-100">
            <h2 className="text-4xl font-bold font-ubuntu mb-8 text-blue-500 text-center">
                Our Offerings for <span className="text-[#ff7722]">K-12 Students</span>
            </h2>
            <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 px-8 pb-4 xl:px-0 gap-8 xl:gap-16">
                {offerigsData.map((item, index) => (
                    <div
                        key={index}
                        className="bg-white rounded-lg overflow-hidden shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl hover:bg-violet-100 duration-300 ease-in-out"
                    >
                        <img
                            src={item.image}
                            alt={item.title}
                            className="w-full h-64 object-cover"
                        />
                        <div className="p-6 text-center text-gray-800">
                            <h3 className="text-xl font-semibold">{item.title}</h3>
                            <p className="mt-2">{item.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default StudentOfferings