import React, {useState} from 'react';
import contactImg from '../assets/contact.jpg';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import CustomForm from './CustomForm';

const Contact = () => {
    const [showForm, setShowForm] = useState(false);

    const handleShowForm = () => {
        setShowForm(true);
    };
    
    const handleCloseForm = () => {
        setShowForm(false);  
    };
    return (
        <>
            {showForm && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-in-out z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg relative transform transition-transform duration-300 ease-in-out scale-95 opacity-100 z-50 animate-popup">  
                        <CustomForm formType="enquiry" title="Enquiry Form" handleClose={handleCloseForm} />
                    </div>
                </div>
            )}

            <div className="flex flex-col md:flex-row items-center justify-between bg-white p-8">

                <div className="md:w-1/2 w-full text-center md:text-center space-y-4">

                    <div className="relative inline-block">
                        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold font-ubuntu text-center text-blue-400 relative z-10">
                            Contact Us
                        </h1>
                    </div>

                    <h2 className="text-sm md:text-base text-center md:text-left font-medium text-black">
                        Get in touch to learn how we can bring Skill Labs to your school or explore our Training Programs and Workshops. We're excited to help transform your school into a center of innovation and learning!
                    </h2>

                    <h2 className="text-sm md:text-base text-center md:text-left font-medium text-black">
                        Fill out the form, and we'll get back to you with the information you need. Let's embark on this journey together!"
                    </h2>

                    <div className='flex justify-center'>
                        <button onClick={handleShowForm} className="border-2 border-black bg-black text-white px-4 py-2 sm:px-6 sm:py-3 md:px-8 md:py-3 rounded-full hover:bg-blue-400 hover:border-blue-400 transition duration-300 ease-in-out">Know More</button>
                    </div>
                </div>


                <div className="md:w-1/2 w-full flex justify-center mt-8 md:mt-0">
                    <img src={contactImg} alt="Contact Us" className="w-80 lg:w-[480px] h-80 object-contain" />
                </div>


            </div>


            <div className="w-full bg-violet-100 flex flex-col p-8 gap-4 lg:flex-row space-y-8 lg:space-y-0 lg:space-x-8">
                <div className="w-full p-4 lg:w-1/2 h-[240px] md:h-[360px] lg:h-auto">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d243.64036930170445!2d76.64021724954007!3d12.299219621824943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDE3JzU3LjEiTiA3NsKwMzgnMjQuOCJF!5e0!3m2!1sen!2sin!4v1728819852618!5m2!1sen!2sin"
                        width="100%"
                        height="100%"
                        allowFullScreen=""
                        loading="lazy"
                        title="Google Map"
                        className="border border-gray-300 rounded-lg shadow-md"
                    ></iframe>
                </div>

                <div className="w-full p-4 lg:w-1/2 grid grid-cols-1 sm:grid-cols-2 gap-8">
                    <div className="flex items-center h-[240px] space-x-4 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">

                        <div className="text-center flex flex-col justify-center items-center mx-auto space-y-2">
                            <FaPhone className="text-3xl text-blue-400 rotate-90" />
                            <h3 className="text-lg font-semibold">Phone</h3>
                            <p className="text-sm">+91 - 9036041555</p>
                            <p className="text-sm mt-0">+91 - 9731280666</p>
                        </div>
                    </div>

                    <div className="flex items-center h-[240px] space-x-4 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">

                        <div className="text-center flex flex-col justify-center items-center mx-auto space-y-2">
                            <FaEnvelope className="text-3xl text-blue-400" />
                            <h3 className="text-lg font-semibold">Email</h3>
                            <p className="text-sm">stemavishkar@gmail.com</p>
                        </div>
                    </div>

                    <div className="flex items-center h-[240px] space-x-4 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">

                        <div className="text-center flex flex-col justify-center items-center mx-auto space-y-2">
                            <FaMapMarkerAlt className="text-4xl text-blue-400" />
                            <h3 className="text-lg font-semibold">Address</h3>
                            <p className="text-sm">#1090, 3rd Floor, Sigma Complex, Vani Vilas Double Rd, Chamaraja Mohalla, Chamarajapuram Mohalla, Lakshmipuram, Mysuru, Karnataka 570005</p>
                        </div>
                    </div>

                    <div className="flex items-center h-[240px] space-x-4 p-4 bg-white border border-gray-300 rounded-lg shadow-lg">

                        <div className="text-center flex flex-col justify-center items-center mx-auto space-y-2">
                            <FaClock className="text-3xl text-blue-400" />
                            <h3 className="text-lg font-semibold">Working Hours</h3>
                            <p className="text-sm">Mon - Sat, 9 AM - 6 PM</p>
                        </div>
                    </div>
                </div>
            </div>
        </>


    )
}

export default Contact