import React from 'react';
import { FaGraduationCap, FaHandsHelping, FaTrophy } from 'react-icons/fa';
import { FaLaptop } from "react-icons/fa6";

const ChooseUs = () => {
    const data = [
        {
            title: 'Skill Lab Setups',
            description: 'We set up interactive technology labs with state-of-the-art tools, allowing students to experiment with Robotics, AI, IoT, and 3D Printing.',
            icon: <FaLaptop className="text-6xl text-purple-400 mb-2" />,
        },
        {
            title: 'Training Programs',
            description: 'Our programs bridge the gap between school learning and real-world applications, covering everything from coding basics to advanced robotics.',
            icon: <FaGraduationCap className="text-6xl text-green-400 mb-2" />,
        },
        {
            title: 'Workshops & Bootcamps',
            description: 'Intensive hands-on experiences where students build AI-driven robots, design drone navigation paths, and explore immersive VR experiences.',
            icon: <FaHandsHelping className="text-6xl text-red-400 mb-2" />,
        },
        {
            title: 'Expos & Competitions',
            description: 'Showcase innovations, participate in mobile robot building, and engage with industry experts during our technology expos and competitions.',
            icon: <FaTrophy className="text-6xl text-blue-400 mb-2" />,
        },
    ];

    return (
        <div className="py-8 bg-violet-100">
            <h2 className="text-4xl font-bold font-ubuntu mb-8 text-blue-500 text-center">
                Why Choose <span className="text-[#ff7722]">Us?</span>
            </h2>

            <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 px-8 xl:px-0 mb-4 gap-8 xl:gap-16">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className="bg-white text-center text-gray-800 rounded-lg p-6 shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl hover:bg-orange-100 duration-300 ease-in-out"
                    >
                        <div className="flex justify-center">
                            {item.icon}
                        </div>
                        <h3 className="text-xl font-semibold">{item.title}</h3>
                        <p className="mt-2">{item.description}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ChooseUs