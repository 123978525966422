import React from 'react';
import aboutImg from '../assets/about.jpg';
import visionImg from '../assets/vision.jpg';
import missionImg from '../assets/mission.png';
import teamImg1 from '../assets/shivanna.webp';
import teamImg2 from '../assets/dasharath.webp';
import teamImg3 from '../assets/dinesh.webp';

const About = () => {
  return (
    <>
      <div className="flex flex-col md:flex-row items-center md:gap-4 justify-between bg-white p-8">

        <div className="md:w-1/2 w-full text-center md:text-center space-y-4">

          <div className="relative inline-block">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold font-ubuntu text-center text-blue-400 relative z-10">
              About Us
            </h1>
          </div>

          <h2 className="text-sm md:text-base text-left font-medium text-black">
            At <span className='font-semibold'>STEM Avishkar Pvt Ltd</span>, we revolutionize education by integrating cutting-edge technology with hands-on innovation. Our programs in Robotics, AI, IoT, Drone Technology, AR, VR, and 3D Printing inspire creativity and critical thinking in K-12 students.
          </h2>

          <h2 className="text-sm md:text-base text-left font-medium text-black">
            We bridge the gap between theory and real-world applications, preparing students to become tomorrow's tech innovators and leaders.
          </h2>
        </div>


        <div className="md:w-1/2 w-full flex justify-center mt-8 md:mt-0">
          <img src={aboutImg} alt="About Us" className="w-full lg:w-[480px] h-80 object-contain" />
        </div>


      </div>

      <div className="flex flex-col md:flex-row md:gap-4 justify-between bg-violet-100 p-8">

        <div className="md:w-1/2 w-full space-y-4">

          <div className="relative flex justify-center md:justify-start">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold font-ubuntu text-center md:text-left text-blue-400 relative z-10">
              What <span className="text-[#ff7722]">We Do?</span>
            </h1>
          </div>

          <p className="text-sm md:text-base text-left font-medium text-black">
            Our offerings aim to create immersive learning environments that bring future technologies to life.
          </p>

          <p className="text-sm md:text-base text-left font-medium text-black">
            We are committed to establishing state-of-the-art skill labs in schools.
          </p>

          <p className="text-sm md:text-base text-left font-medium text-black">
            This commitment ensures that students from diverse academic backgrounds—whether they follow the CBSE, ICSE, IGCSE, IB, or State Board curricula can access the necessary learning experiences to excel.
          </p>
        </div>

        <div className="md:w-1/2 w-full space-y-4 mt-8 md:mt-0">

          <div className="space-y-2">
            <h3 className="text-lg md:text-xl font-semibold text-blue-400">01. Skill Lab Design & Setup</h3>
            <p className="text-sm md:text-base text-black">
              We create cutting-edge labs customized to your school's needs, featuring technologies like Robotics, AI, IoT, drones, AR/VR, and 3D printing.
            </p>
          </div>

          <div className="space-y-2">
            <h3 className="text-lg md:text-xl font-semibold text-blue-400">02. Customized Curriculum Development</h3>
            <p className="text-sm md:text-base text-black">
              Our STEM-aligned curriculum is tailored to each grade level, providing a progressive learning path in emerging technologies.
            </p>
          </div>

          <div className="space-y-2">
            <h3 className="text-lg md:text-xl font-semibold text-blue-400">03. Teacher Training & Support</h3>
            <p className="text-sm md:text-base text-black">
              We equip teachers with the tools and knowledge to integrate tech-based learning into their classes, ensuring effective guidance for students.
            </p>
          </div>

          <div className="space-y-2">
            <h3 className="text-lg md:text-xl font-semibold text-blue-400">04. Specialized Workshops & Bootcamps</h3>
            <p className="text-sm md:text-base text-black">
              In addition to regular activities, we offer intensive workshops and bootcamps in areas like AI, robotics, drone operation, and 3D modeling.
            </p>
          </div>

        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center md:gap-4 justify-between bg-orange-100 p-8">

        <div className="md:w-1/2 w-full space-y-4">

          <div className="relative flex justify-center md:justify-start">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold font-ubuntu text-center md:text-left text-blue-400 relative z-10">
              Our <span className="text-[#ff7722]">Vision</span>
            </h1>
          </div>

          <p className="text-sm md:text-base text-left font-medium text-black">
            Empowering the next generation to become problem solvers, inventors, and leaders in emerging technologies.
          </p>

          <p className="text-sm md:text-base text-left font-medium text-black">
            We aim to equip young minds with the knowledge, tools, and confidence to shape the future through engaging, technology-driven, STEM-based education. We envision a world where students are not just consumers of technology but active creators, leveraging their skills to solve complex problems, innovate, and lead.
          </p>
        </div>

        <div className="md:w-1/2 w-full flex justify-center mt-8 md:mt-0 space-y-4">
          <img src={visionImg} alt="Vision" className="w-60 h-auto object-cover rounded-full" />
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center md:gap-4 justify-between bg-violet-100 p-8">
        <div className="md:w-1/2 w-full flex justify-center space-y-4">
          <img src={missionImg} alt="Mission" className="w-60 h-auto object-cover rounded-full" />
        </div>

        <div className="md:w-1/2 w-full space-y-4 mt-8 md:mt-0">

          <div className="relative flex justify-center md:justify-start">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold font-ubuntu text-center md:text-left text-blue-400 relative z-10">
              Our <span className="text-[#ff7722]">Mission</span>
            </h1>
          </div>

          <p className="text-sm md:text-base text-left font-medium text-black">
            To equip K-12 students with 21st-century skills through hands-on learning in robotics, AI, IoT, and more, fostering a culture of innovation and creativity.
          </p>

          <p className="text-sm md:text-base text-left font-medium text-black">
            We aim to provide accessible, hands-on learning experiences that integrate the latest advancements in emerging technologies. Through our comprehensive skill labs and specialized training programs, we strive to inspire creativity and develop problem-solving skills that will prepare students for the challenges of tomorrow. We believe every student, regardless of background, should have the opportunity to explore and master the tools of innovation.
          </p>
        </div>
      </div>

      <div className="flex flex-col bg-orange-100 p-8">
        <div className="w-full text-center mb-8">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold font-ubuntu text-blue-400">
            Our Core <span className="text-[#ff7722]">Values</span>
          </h1>
        </div>

        <div className="flex flex-col md:grid md:grid-cols-2 md:gap-8">

          <div className="space-y-2 mb-8">
            <h3 className="text-lg md:text-xl font-semibold text-blue-400">01. Innovation</h3>
            <p className="text-sm md:text-base text-black">
              We constantly push the boundaries of what’s possible in education, integrating the latest technologies to offer students experiences that keep them ahead of the curve.
            </p>
          </div>


          <div className="space-y-2 mb-8">
            <h3 className="text-lg md:text-xl font-semibold text-blue-400">02. Excellence</h3>
            <p className="text-sm md:text-base text-black">
              Our commitment to delivering high-quality, impactful learning experiences ensures that students leave our programs equipped with tangible skills and knowledge.
            </p>
          </div>


          <div className="space-y-2 mb-8">
            <h3 className="text-lg md:text-xl font-semibold text-blue-400">03. Accessibility</h3>
            <p className="text-sm md:text-base text-black">
              We believe every student should have access to the tools and learning opportunities that prepare them for the future, regardless of their background.
            </p>
          </div>


          <div className="space-y-2 mb-8">
            <h3 className="text-lg md:text-xl font-semibold text-blue-400">04. Collaboration</h3>
            <p className="text-sm md:text-base text-black">
              By working closely with schools, teachers, parents, and students, we create an environment that fosters teamwork and mutual growth.
            </p>
          </div>


          <div className="space-y-2 mb-8">
            <h3 className="text-lg md:text-xl font-semibold text-blue-400">05. Lifelong Learning</h3>
            <p className="text-sm md:text-base text-black">
              We inspire a love of learning in students, encouraging them to continuously explore, create, and challenge themselves well beyond the classroom.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-violet-100 p-8">
        <div className="w-full text-center mb-8">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold font-ubuntu text-blue-400">
            Our <span className="text-[#ff7722]">Team</span>
          </h1>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 mb-4 gap-8">
          <div className="flex flex-col items-center p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-lg">
            <img
              src={teamImg1}
              alt="Team Member 1"
              className="w-48 h-48 rounded-full mb-4"
            />
            <h3 className="text-lg md:text-xl font-semibold text-blue-400">Dr. Shivanna D M</h3>
            <p className="text-sm md:text-base text-black text-center">
              Director
            </p>
          </div>

          <div className="flex flex-col items-center p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-lg">
            <img
              src={teamImg2}
              alt="Team Member 2"
              className="w-48 h-48 rounded-full mb-4"
            />
            <h3 className="text-lg md:text-xl font-semibold text-blue-400">Dasharath K</h3>
            <p className="text-sm md:text-base text-black text-center">
              Director
            </p>
          </div>

          <div className="flex flex-col items-center p-4 bg-gray-100 border border-gray-300 rounded-lg shadow-lg">
            <img
              src={teamImg3}
              alt="Team Member 3"
              className="w-48 h-48 rounded-full mb-4"
            />
            <h3 className="text-lg md:text-xl font-semibold text-blue-400">Dinesh Bopanna</h3>
            <p className="text-sm md:text-base text-black text-center">
              Director
            </p>
          </div>
        </div>
      </div>


    </>
  )
}

export default About