import React from 'react';
import productImg from '../assets/product.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import OurPrograms from './OurPrograms';
import img1 from '../assets/P11.jpg';
import img2 from '../assets/2.jpg';
import img3 from '../assets/3.jpg';
import img4 from '../assets/P21.jpg';
import img5 from '../assets/P22.jpg';
import img6 from '../assets/P23.jpg';
import img7 from '../assets/5.jpg';
import img8 from '../assets/P31.jpg';
import img9 from '../assets/P32.jpg';
import img10 from '../assets/P41.jpg';
import img11 from '../assets/7.jpg';
import img12 from '../assets/P43.jpg';
import img13 from '../assets/P51.jpg';
import img14 from '../assets/P52.jpg';
import img15 from '../assets/P53.jpg';
import img16 from '../assets/P61.jpg';
import img17 from '../assets/P62.jpg';
import img18 from '../assets/P63.jpg';

const Program = () => {
  const content = [
    {
      id: 1,
      title: 'Robotics Labs',
      description: 'Hands-on robotics kits, humanoid robots, and AI-based robotics education. Students explore real-world robotics systems through interactive learning.',
      images: [img1, img2, img3],
      delay: 2000
    },
    {
      id: 2,
      title: 'IoT Labs',
      description: 'Equip students to explore smart technology and connected devices. They learn how sensors and networks enable cutting-edge innovations in various industries.',
      images: [img4, img5, img6],
      delay: 2500
    },
    {
      id: 3,
      title: 'Drone Technology Labs',
      description: 'Students learn drone mechanics, piloting, and coding in an exciting hands-on environment. Prepare future drone engineers with real-world flight experience.',
      images: [img7, img8, img9],
      delay: 2000
    },
    {
      id: 4,
      title: 'AR / VR Labs',
      description: 'Augmented and virtual reality-based learning modules offer immersive education. Students engage in interactive simulations to enhance their understanding of complex concepts.',
      images: [img10, img11, img12],
      delay: 2500
    },
    {
      id: 5,
      title: '3D Printing & CAD Labs',
      description: 'From design to production, students explore creativity through 3D modeling and printing. Learn CAD software and experience the full prototyping process.',
      images: [img13, img14, img15],
      delay: 2000
    },
    {
      id: 6,
      title: 'Mechanical, Electronics & Electrical Labs',
      description: 'Practical learning for aspiring engineers with hands-on experience in mechanics, electronics, and electrical systems. Students build real-world problem-solving skills.',
      images: [img16, img17, img18],
      delay: 2500
    }
  ];

  return (
    <>
      <div className="flex flex-col md:flex-row items-center md:gap-4 justify-between bg-white p-8">

        <div className="md:w-1/2 w-full text-center md:text-center space-y-4">

          <div className="relative inline-block">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold font-ubuntu text-center text-blue-400 relative z-10">
              Products and Services
            </h1>
          </div>

          <h2 className="text-sm md:text-base text-left font-medium text-black">
            We offer cutting-edge educational products and services designed to immerse K-12 students in hands-on learning. Our Skill Lab setups include Robotics, IoT, Drone Technology, AR/VR, 3D Printing, and Engineering labs.
          </h2>

          <h2 className="text-sm md:text-base text-left font-medium text-black">
            We also provide specialized training programs, workshops, and bootcamps in fields like AI, coding, and design. Additionally, we offer continuous maintenance and support for school lab infrastructures.
          </h2>
        </div>


        <div className="md:w-1/2 w-full flex justify-center mt-8 md:mt-0">
          <img src={productImg} alt="About Us" className="w-full lg:w-[480px] h-80 object-contain" />
        </div>


      </div>

      <div className="flex flex-col bg-violet-100 p-8">
        <div className="w-full text-center mb-8">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold font-ubuntu text-blue-400">
            Skill Lab <span className="text-[#ff7722]">Setups</span>
          </h1>
        </div>

        <div className="flex flex-col lg:grid lg:grid-cols-2 lg:gap-8">
          {content.map((item) => (
            <div key={item.id} className="space-y-4 mb-4">
              <div className="flex flex-col mb-4 md:mb-0">
                <h3 className="text-lg md:text-xl py-2 font-semibold text-blue-400">
                  {item.id}. {item.title}
                </h3>
                <p className="text-sm md:text-base py-2 text-black">
                  {item.description}
                </p>
              </div>

              <div className="w-full">
                <Swiper
                  effect={'flip'}
                  grabCursor={true}
                  centeredSlides={true}
                  slidesPerView={1}
                  autoplay={{
                    delay: item.delay,
                    disableOnInteraction: false,
                  }}
                  loop={true}
                  className="mySwiper"
                  modules={[Autoplay]}
                >
                  {item.images.map((image, index) => (
                    <SwiperSlide key={index} className='flex justify-center'>
                      <img src={image} alt={`Slide ${index + 1}`} className="w-[250px] h-[200px] md:w-[600px] md:h-[300px] object-cover rounded-lg" />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          ))}
        </div>

      </div>

      <OurPrograms bgColor="bg-orange-100" hoverColor="bg-violet-100" />
    </>
  )
}

export default Program